import { APIFilter } from '@/utils/api'

export default {
  async selectPresupuestocliSubsis (Vue, filter, search, sorter, page, idpresupuestocli) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('idpresupuestocli', idpresupuestocli)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.presupuestocliSubsis.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'presupuestocliSubsis.select',
      {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectPresupuestocliSubsisRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idpresupuestocli_subsis', pks)
    const resp = await Vue.$api.call('presupuestocliSubsis.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
